import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/SEO/seo"
import Blurb from "../components/Blurb/blurb"
import Experience from "../components/Experience/experience"
import { profileData, workData, educationData, interestData } from "../siteData/data"

const IndexPage = () => (
  <Layout>
    <SEO title="Ryan Duffield" />
    <Blurb title={profileData.title} text={profileData.text} />
    <Experience title={workData.title} experience={workData.experience} />
    <Experience title={educationData.title} experience={educationData.experience} />
    <Blurb title={interestData.title} text={interestData.text} /> 
  </Layout>
)

export default IndexPage
