import React from "react"

import "./experience.scss"

const Experience = ({ title, experience }) => (
	<div className='experience-container'>
		<h3 className='experience-title'>{title}</h3>
		{experience.map((item) => {
			if(item.responsibilities !== undefined) {
				return (
					<div>
						<div className='experience-job-title'>{item.title}</div>
						<div className='experience-job-subtitle'>{item.subtitle}</div>
						<ul style={{ margin: 0, padding: 0, color: `$light-grey` }}>
						{item.responsibilities.map((item, i, array) => {
							if(i === array.length - 1) {
								return <li className='experience-item--last' key={i}><span className='experience-text'>{item}</span></li>
							}
							return <li className='experience-item' key={i}><span className='experience-text'>{item}</span></li>
						})}
						</ul>
					</div>
				)
			} else {
				return (
					<div>
						<div className='experience-job-title'>{item.title}</div>
						<div className='experience-job-subtitle'>{item.subtitle}</div>
						<div className='experience-job-subtitle--empty'></div>
					</div>
				)
			}
		})}
	</div>
)

export default Experience
